import { SettingConstant } from '../constants/settings';
import { APIHelper } from '../helpers';

function getAllTokenData() {
  const tokenData = localStorage.getItem(SettingConstant.TOKEN_DATA);
  if (tokenData) {
    return JSON.parse(tokenData);
  }
  return null;
}

async function checkTokenValid() {
  const tokenData = getAllTokenData();
  if (!tokenData || tokenData.error) return false;

  const resp = await APIHelper.getRequest(`${SettingConstant.UAM_SERVICE_URL}check-token-valid`); // httpService.get(`${SettingConstant.UAM_SERVICE_URL}check-token-valid`);
  return resp !== 'Unauthorized';

  /*const response = await httpService.get(`${SettingConstant.APP_SERVICE_URL}FilterCriteria?Filter_Name=Carrier`)
  if (response && response.error && response.error.status !== "401") throw Error(response.error)
  return Array.isArray(response)*/
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function getAccessTokenData(tokenData) {
  if (!tokenData) tokenData = getAllTokenData();
  if (!tokenData) return null;

  return parseJwt(tokenData.id_token);
}

export { getAllTokenData, getAccessTokenData, checkTokenValid };
